import React from 'react';
import '../css/Navbar.css'; // Import the CSS file for styling
import I1 from '../Images/InstaIcon.png';
import I2 from '../Images/WhatsappIcon.png';
import I3 from '../Images/FacebookIcon.png';
import mapsLoc from '../Images/googleMapsIcon.png';


const handleMapLink = () => {
  // Replace the coordinates with your desired location
  const latitude = 28.707846376843317;
  const longitude = 77.12023881204526;
  const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  window.open(mapUrl, '_blank');
};


const Navbar = () => {

  return (
    <nav className="navbar">
      <ul className="nav-lists">
        <li><a href="/">Home</a></li>
        <li><a href="#About">About</a></li>
        <li><a href="#Gallery">Gallery</a></li>
        <li><a href="#Contact">Contact</a></li>
        

      </ul>
      <div className="social-medias" >

        <div onClick={handleMapLink} className='locate-icon1'>
                    <img src={mapsLoc} style={{width: "32px"}}/>
        </div>
        <a href="https://www.instagram.com/ganpati_realestate_rohini?igsh=MXV1MnJrOTlwam1qNg==" target="_blank" rel="noopener noreferrer"> <img src={I1} style={{width: "31px", marginTop: '3px'}}/> </a>
         
        {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i style={{color:"#0077b5" ,backgroundColor:"white"}} className="fab fa-linkedin"></i></a> */}
        
        <a href="https://www.facebook.com/profile.php?id=61558049436052&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><img src={I3} style={{width: "36px"}}/></a>
        
        <a href="https://api.whatsapp.com/send?phone=7042166816" target="_blank" rel="noopener noreferrer"><img src={I2} style={{width: "32px"}}/></a>

      </div>    
    </nav>
  );
};

export default Navbar;