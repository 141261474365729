import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../css/testimonials.css";

const testimonials = [
  { text: "We heard a lot about Ganpati Real Estate - Rohini and they did not disappoint. They were able to provide us with the right house after putting much effort into it and had good customer service.", author: "~ Kamla Rani (Sector- 7, Rohini)" },
  { text: "As a fellow real estate professional, I can confidently say that Ganpati Real Estate - Rohini sets the standard for excellence in the industry. Their integrity and commitment to client satisfaction make them a trusted partner.", author: "~ Sunil Jain (Siddhi Vinayak Properties)" },
  { text: "I can attest to the outstanding service provided by Ganpati Real Estate - Rohini. Their collaborative spirit and dedication to client success make them a trusted partner in the industry.", author: "~ Anil Agarwal (Agarwal Properties)" },
  { text: "Working with Ganpati Real Estate- Rohini was a pleasure from beginning to end. Their expertise in the market and their readiness to go that extra mile for their customers really made them outstanding.", author: "~ Abhishek Garg (Sector- 8, Rohini)" },
];

const TestimonialsCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 7000); // Change the slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='box-layout'>
      <Helmet>
                {/* Google Analytics tag */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z08W7J3JYH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z08W7J3JYH');
                    `}
                </script>
            </Helmet>
            
      
      <div className="carousel-container">
        <div className="carousel-slide">
          <div className="testimonial-item">
            
            <p className="testimonial-text">{testimonials[activeIndex].text}</p>
            <p className="testimonial-author">{testimonials[activeIndex].author}</p>

          </div>
        </div>
        <div className="carousel-dots">
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
            ></span>
          ))}
        </div>
      </div>

    </div>
  );
};

export default TestimonialsCarousel;
