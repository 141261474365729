import React from 'react';
import '../css/PropertyCard.css';

const PropertyCard = ({ property }) => {
  return (
    <div className="property-card">
      <div class="card-container-row">
        <div class="box1"><img src={property.image} alt={property.name} className="property-image" /></div>
        <div class="box2">
          <h3 className="property-name">{property.name}</h3>
          <p className="property-address">{property.address}</p>
          <p className="property-locality">{property.locality}</p>
          <p>
            <strong>Verified: </strong>
            {property.verified ? 'Yes' : 'No'}
          </p>
          <p>
            <strong>Furnished: </strong>
            {property.furnished ? 'Yes' : 'No'}
          </p>
          <p className="property-specifications">{property.specifications}</p></div>
      </div>  
    </div>
  );
};

export default PropertyCard;