import React, { useState, useEffect } from 'react';
import "../css/ImageGallery.css";
import { Helmet } from 'react-helmet';
import TestimonialsCarousel from "../components/testimonials";

import I1 from "../Images/Properties/P2.jpg";
import I3 from "../Images/Properties/Door.jpg";
import I6 from "../Images/Properties/Hall.jpg";
import I5 from "../Images/Properties/P1.jpg";
import I2 from "../Images/Properties/Kitchen.jpg";
import I4 from "../Images/Properties/Bathroom.jpg";


import insightImg from "../Images/Insights.webp";

const ImageGallery = () => {
    const [activeIndex, setActiveIndex] = useState(-1); // Index instead of image object

    // Local array of images
    const images = [
        { src: I1, alt: 'Description of image 1' },
        { src: I2, alt: 'Description of image 2' },
        { src: I3, alt: 'Description of image 3' },
        { src: I4, alt: 'Description of image 4' },
        { src: I5, alt: 'Description of image 5' },
        { src: I6, alt: 'Description of image 6' }
    ];

    
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNext();
            } else if (event.key === 'ArrowLeft') {
                handleBack();
            } else if (event.key === 'Escape') {
                handleClose();
            }
        };

        // Add event listeners
        window.addEventListener('keydown', handleKeyDown);

        // Clean up
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeIndex, images.length]);

    const handleImageClick = (index) => {
        setActiveIndex(index);
    };

    const handleClose = () => {
        setActiveIndex(-1);
    };

    const handleNext = () => {
        if (activeIndex < images.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handleBack = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    return (
        <div>
            <Helmet>
                {/* Google Analytics tag */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z08W7J3JYH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z08W7J3JYH');
                    `}
                </script>
            </Helmet>
            
            <div className='bckSection'>
                
                <div>           {/* Gallery */}
                <div>
                    <h1 className='gradient-underline1'>
                        Gallery
                    </h1>
                </div>
            
            <div style={{marginBottom:"-5%",marginTop:"3%"}}>    
            <div className="gallery">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt}
                        onClick={() => handleImageClick(index)}
                        className="gallery-image"
                    />
                ))}
            </div>

            {activeIndex !== -1 && (
                <div className="lightbox" onClick={handleClose}>
                    <img src={images[activeIndex].src} alt={images[activeIndex].alt} className="active-image" />
                    <button className="arrow left" onClick={(e) => { e.stopPropagation(); handleBack(); }}>&#10094;</button>
                    <button className="arrow right" onClick={(e) => { e.stopPropagation(); handleNext(); }}>&#10095;</button>
                </div>
            )}
            </div>

            </div>

            <div>           {/* Insights */}
                <div>
                    <h1 className='gradient-underline2'>
                        Insights on Us
                    </h1>
                </div>
            
                <div className="container-Insight">
                    <div className='container-Insight-text' ><p>It can be difficult to rent or buy a house, but with our help, based in Rohini-7, Delhi, the process is easy. 
                        We meet all of your needs as top-notch real estate agents with professionalism, trust, and respect. Our team is made up of young, active workers with a lot of experience, 
                        which means we can provide excellent service. We stand out as one of the best real estate agents in Rohini, 
                        especially in sectors 3, 5, 6, 7, 8, 9, 20, 21, 22, 23, 24, and 25.
                         Take advantage of our free consultation services to get in touch with Ganpati Real Estate, the top RERA-certified real estate agent.
</p>
                    </div>
                    <div>
                        <img src={insightImg} alt="Insights on Us" className='insight-image'/>
                    </div>
                </div>
                </div>

                <div>           {/* testimonials */}
                <div>
                    <h1 className='gradient-underline3'>
                        Testimonials
                    </h1>
                </div>
            
                <div >
                    
                    <TestimonialsCarousel />
                </div>
                </div>

            </div>
        </div>
    );
};

export default ImageGallery;
