import React from 'react';
import '../css/HomePage.css';

const EmailLink = () => {
  const emailAddress = 'ganpatirealestate01@gmail.com'; // Replace with your email address

  return (
    <a className="contact-on-images" href={`mailto:${emailAddress}`}>ganpatirealestate01@gmail.com</a>
  );
};

export default EmailLink;
