import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import "../css/Contact.css";
import axios from 'axios';

function Contact() {
  
  // Telegram API Fn
  const [type, setType] = useState("");

  // Function to handle changes in the dropdown
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  

const [formData, setFormData] = useState({
  name: '',
  number: '',
  email:'',
  subjectdata:''
});

const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};


const sendTelegramMsg = async (e) => {
  e.preventDefault();

  const message = `Name: ${formData.name}\nPhone Number: ${formData.number}\nType:${type}\nEmail-id:${formData.email}\nSubject:${formData.subjectdata}`;

  try {
    await axios.post(`https://api.telegram.org/bot7008276209:AAG1FW1cclH6Op1zF8nCLcqZrE60PAnD2Jc/sendMessage`, {
      chat_id: '-4076631645',
      text: message
    });
    alert('Advisor from Ganpatirealestate will contanct you soon!');
        // Reset the form data to its initial state
        setFormData({
          name: '',
          number: '',
          email:'',
          subjectdata:''
        });
        setType("");
  } catch (error) {
    console.error('Error sending message:', error);
    alert('Failed to send message. Please try again later.');
  }
};


  return (
    <div className="contact-container">
      <Helmet>
                {/* Google Analytics tag */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z08W7J3JYH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z08W7J3JYH');
                    `}
                </script>
            </Helmet>
            
      <div className="contact-info">
        <div className="info-item">
          <h3
            style={{
              display: "inline-flex",
              alignItems: "center",
              margin: "0",
            }}
          >
            <div
              style={{
                width: "37px",
                height: "37px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "indianred", fontSize: "inherit" }}
                className="fa-solid fa-location-dot"
              ></i>
            </div>
            &nbsp; Our Office Address
          </h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            B-6/151, Sector-7, Rohini, Delhi-110085
          </p>
        </div>

        <div className="info-item">
          <h3
            style={{
              display: "inline-flex",
              alignItems: "center",
              margin: "0",
            }}
          >
            <div
              style={{
                width: "37px",
                height: "37px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "black", fontSize: "inherit" }}
                className="fa-solid fa-phone"
              ></i>
            </div>
            &nbsp; Call Us
          </h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +91 -
            7042166816
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +91 -
            9868206000
          </p>
        </div>

        <div className="info-item">
          <h3
            style={{
              display: "inline-flex",
              alignItems: "center",
              margin: "0",
            }}
          >
            <div
              style={{
                width: "37px",
                height: "37px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                style={{ color: "cornflowerblue", fontSize: "inherit" }}
                className="fa-solid fa-envelope"
              ></i>
            </div>
            &nbsp; General Enquiries
          </h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ganpatirealestate01@gmail.com
          </p>
        </div>

        <div className="info-item">
          <h3
            style={{
              display: "inline-flex",
              alignItems: "center",
              margin: "0",
              marginLeft: "4px",
            }}
          >
            <div>
              <i
                style={{ color: "white", fontSize: "xx-large" }}
                className="fa-regular fa-clock"
              ></i>
            </div>
            &nbsp; Our Timing
          </h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mon -
            Sun: 10:00 AM - 09:00 PM
          </p>
        </div>
      </div>

      <div className="contact-form">
        <h2>Contact Us</h2>
        <form onSubmit={sendTelegramMsg}>
          <input type="text" placeholder="Name" name="name" value={formData.name} onChange={handleChange} required />
          <input
            type="text"
            placeholder="Phone"
            name="number"
            value={formData.number} 
            onChange={handleChange}
            pattern="[0-9]{10,}"
            title="Please write correct phone number (minimum 10 digits)"
            required
          />
          <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required />

          {/* <input type="text" placeholder="Name" name="name" required /> */}
          <select value={type} 
                    onChange={handleTypeChange}>
            <option value="">Select Type</option>
            <option value="Rent">Rent</option>
            <option value="Buy">Buy</option>
            <option value="Sale">Sale</option>
            <option value="Legal">Legal Advisary</option>
          </select>

          <textarea placeholder="Subject" name="subjectdata" value={formData.subjectdata} 
                    onChange={handleChange}></textarea>

          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
