import React from 'react';
import "../css/ListProperty.css";
import PropertyCard from '../components/PropertyCard';


import I1 from "../Images/Properties/P2.jpg";
import I3 from "../Images/Properties/Door.jpg";
import I6 from "../Images/Properties/Hall.jpg";



const properties = [
  {
    image: I1,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I3,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I6,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I1,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I3,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I6,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I1,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I3,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I6,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I1,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I3,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  {
    image: I6,
    name: 'Dda Flats 4.0',
    address: 'Sector 15 Rohini, North Delhi',
    locality: 'Near mall,movie theatre, school, metro,hospital,park,gym, yoga',
    verified: true,
    furnished: true,
    specifications: 'Private Room + Deposit 2 months rent',
  },
  // More properties...
];

const ListProperty = () => {
    
  return (
    <div className="property-grid">
    {properties.map((property) => (
      <PropertyCard key={property.name} property={property} />
    ))}
  </div>
  );
};

export default ListProperty;
