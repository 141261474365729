import React from "react";
import "../css/Footer.css"; // Import the CSS file for styling
import I1 from "../Images/InstaIcon.png";
import I2 from "../Images/WhatsappIcon.png";
import I3 from "../Images/FacebookIcon.png";
import I4 from "../Images/googleMapsIcon.png";

const Footer = () => {
  const handleMapLink = () => {
    // Replace the coordinates with your desired location
    const latitude = 28.707846376843317;
    const longitude = 77.12023881204526;
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <div className="footer">
      <div className="social-media">

        <div onClick={handleMapLink} className="ch1">
          <i
            style={{ color: "indianred", fontSize: "x-large" }}
            className="fa-solid fa-location-dot"
          ></i>
          &nbsp;  B-6/151, Sector-7, Rohini, Delhi-110085
        </div>

        <div className="ch2a" onClick={handleMapLink}>
            <img src={I4} className="ch-Image2" /> 
            <p className="ch22">Locate Us{" "}</p>
        </div>

        <a
          className="ch3"
          href="https://www.instagram.com/ganpati_realestate_rohini?igsh=MXV1MnJrOTlwam1qNg=="
          target="_blank"
          rel="noopener noreferrer"
        >
          
          <img src={I1}  className="ch-Image3"/> 
           
          <div className="ch2"> Instagram</div>
        </a>

        <a
        className="ch4"
          href="https://www.facebook.com/profile.php?id=61558049436052&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={I3} className="ch-Image4" /> 
          <div className="ch2"> Facebook</div>
        </a>

        <a 
        className="ch5"
          href="https://api.whatsapp.com/send?phone=7042166816"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={I2} className="ch-Image5" /> 
            <div className="ch2w"> +91-7042166816</div>
        </a>
      </div>

      <div className="copyright-text">
        &copy; Copyright 2024- All Rights Reserved To Ganpati Real Estate
      </div>
    </div>
  );
};

export default Footer;
