// Slideshow.js
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import "../css/HomePage.css";
import I1 from "../Images/Image10.jpeg";
// import I2 from '../Images/Image20.jpeg';
import I2 from "../Images/hh.png";
import I3 from "../Images/Image30.jpeg";
import BrandLogo from "../Images/BrandImg.jpg"; // Import your brand logo image here

import EmailLink from "./EmailLink";
import axios from 'axios';

// const images = [I1, I2, I3];
const images = [I2];

const HomePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const selectElement = document.querySelector(".formInput-select");

    const handleChange = () => {
        // Change text color to black when an option is selected
        selectElement.style.color = "black";
        selectElement.style.backgroundColor = "rgb(232, 240, 254)";
    };

    // Add event listener to the select element
    selectElement.addEventListener("change", handleChange);

    // Cleanup: Remove event listener when component unmounts
    return () => {
        selectElement.removeEventListener("change", handleChange);
    };
}, []);


  const handleMapLink = () => {
    // Replace the coordinates with your desired location
    const latitude = 28.707846376843317;
    const longitude = 77.12023881204526;
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

// Telegram API Fn

const [formData, setFormData] = useState({
  name: '',
  number: ''
});

const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};

const [type, setType] = useState("");

// Function to handle changes in the dropdown
const handleTypeChange = (event) => {
  setType(event.target.value);
};


const sendTelegramMsg = async (e) => {
  e.preventDefault();

  const message = `Name: ${formData.name}\nPhone Number: ${formData.number}\nType:${type}`;

  try {
    await axios.post(`https://api.telegram.org/bot7008276209:AAG1FW1cclH6Op1zF8nCLcqZrE60PAnD2Jc/sendMessage`, {
      chat_id: '-4076631645',
      text: message
    });
    // Reset the form data to its initial state
    setFormData({
      name: '',
      number: ''        
    });
    setType("");
    alert('Advisor from Ganpatirealestate will contanct you soon!');
  } catch (error) {
    console.error('Error sending message:', error);
    alert('Failed to send message. Please try again later.');
  }
};


  return (
    <div className="slideshow-container">
      <Helmet>
                {/* Google Analytics tag */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z08W7J3JYH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z08W7J3JYH');
                    `}
                </script>
            </Helmet>
            
      {images.map((image, index) => (
        <div
          key={index}
          className={`slide ${index === currentImageIndex ? "active" : ""}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          {/* This div is only for Blurr effect */}
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(211, 211, 211, 0.5)", // Light grey with 50% opacity
              pointerEvents: "none", // Allow clicks to pass through the overlay
            }}
          ></div>

          {/* Brand Logo */}
          <div className="brand-logo-container">
            <img src={BrandLogo} alt="Brand Logo" className="brand-logo" />
          </div>

          {/* Middle Content */}
          <div>
            <div className="form-Data-Fetch">
              <div className="tag1">Let Us</div>
              <div className="tag2">Guide You Home</div>
            </div>

            <div className="formDiv">
              <form className="formDetail" onSubmit={sendTelegramMsg}>
                <div className="inputRow">
                  <input
                    className="formDetail-input1"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name} 
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="formDetail-input2"
                    type="text"
                    placeholder="Phone"
                    name="number"
                    value={formData.number} 
                    onChange={handleChange}
                    pattern="[0-9]{10,}"
                    title="Please write correct phone number (minimum 10 digits)"
                    required
                  />
                  <select
                    value={type} 
                    onChange={handleTypeChange}
                    className="formInput-select"
                  >
                    <option value="">Select Type</option>
                    <option value="Rent">Rent</option>
                    <option value="Buy">Buy</option>
                    <option value="Sale">Sale</option>
                    <option value="Legal">Legal Advisary</option>
                  </select>
                </div>
                <div className="buttonRow">
                  <button type="submit" className="formButton">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Contact Details */}
          <div className="contact-details">
            <div>
              <a
                className="contact-on-images"
                href="https://api.whatsapp.com/send?phone=7042166816"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  style={{ color: "green", fontSize: "x-large" }}
                  className="fab fa-solid fa-whatsapp"
                ></i>
                &nbsp; +91-7042166816{" "}
              </a>
            </div>

            <div className="contact-on-images">
              <i
                className="fa-solid fa-envelope"
                style={{ color: "cornflowerblue", fontSize: "x-large" }}
              ></i>
              &nbsp; <EmailLink />
            </div>

            <div className="contact-on-images" onClick={handleMapLink}>
              <i
                style={{ color: "indianred", fontSize: "x-large" }}
                className="fa-solid fa-location-dot"
              ></i>
              &nbsp; B-6/151, Sector-7
              <br></br>&nbsp;&nbsp;&nbsp;&nbsp; Rohini, Delhi-110085
            </div>

            <div
              className="contact-on-images"
              href="/contact"
              onClick={handleMapLink}
            >
              <i
                style={{ color: "darkblue", fontSize: "x-large" }}
                className="fa-solid fa-location-arrow"
              ></i>
              &nbsp; Locate Us
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomePage;
