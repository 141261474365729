import React, { useState, useRef } from 'react';  
import { Helmet } from 'react-helmet';
// import './Services.css'; // Ensure you have a corresponding CSS file for styles
import '../css/Services.css'; // Import the CSS file for styling
import I1 from '../Images/BuyProperty.webp';
import I2 from '../Images/ForRent.webp';
import I3 from '../Images/ForSale.webp';
import I4 from '../Images/Registration.webp';
import I5 from '../Images/Banner.png';


import insta from '../Images/InstaIcon.png';
import whastapp from '../Images/WhatsappIcon.png';
import facebook from '../Images/FacebookIcon.png';
import mapsLoc from '../Images/googleMapsIcon.png';

import Icon1 from '../Icons/Icon1.webp';
import Icon2 from '../Icons/Icon2.png';
import Icon3 from '../Icons/Icon3.png';
import Icon4 from '../Icons/Icon4.png';
import Contacts from './Contact';
// import Icon1 from '../Images/Icon1.png'

// Data for the services cards
const servicesData = [
  {
    title: 'Buy Property',
    description: 'Discover homes that fit into your price range. Tell us your budget and we will find the best homes at best place.',
    buttonText: 'Enquiry Now',
    imageSrc: I1 // replace with actual path to image
  },
  {
    title: 'Rent Property',
    description: 'Invest in real estate today and reap the benefits tomorrow with best property dealer in Rohini.',
    buttonText: 'Enquiry Now',
    imageSrc: I2 // replace with actual path to image
  },
  {
    title: 'Resale Property',
    description: 'Sell your existing property to make the most out of it. We will direct the right customers to you.',
    buttonText: 'Enquiry Now',
    imageSrc: I3 // replace with actual path to image
  },
  {
    title: 'Registration',
    description: 'Stay out of disagreements or misunderstandings. We got you covered to stay legally secured.',
    buttonText: 'Enquiry Now',
    imageSrc: I4 // replace with actual path to image
  },
];

const handleMapLink = () => {
  // Replace the coordinates with your desired location
  const latitude = 28.707846376843317;
  const longitude = 77.12023881204526;
  const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  window.open(mapUrl, '_blank');
};


const Services = () => {

  const scrollToRef = useRef(null);
  const [showContacts, setShowContacts] = useState(false);

  const openContactSection = () => {
    // Scroll to the component using the scrollToRef's current value
    scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    // setShowContacts(true);
  };

  const ServiceCard = ({ title, description, buttonText, imageSrc }) => (
    <div className="service-card">
      <img src={imageSrc} alt={title} className="service-image" />
      <div className="service-info">
        <h3>{title}</h3>
        <p>{description}</p>
        <button onClick={openContactSection} className="service-button">{buttonText}</button>
      </div>
    </div>
  );
  

  return (
  <div>
  <Helmet>
                {/* Google Analytics tag */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z08W7J3JYH"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z08W7J3JYH');
                    `}
                </script>
            </Helmet>
            
    <div className="services">
      <h1>Our Services</h1>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
    
    <div className="row">  {/* Added class for styling */}
      <div className="column image-column">  {/* Column for image */}
        <img src={I5} alt="Property Display" className="service-row-image"/>
      </div>

      <div className="column content-column">  {/* Column for heading and paragraph */}
          <h2>Best Property Dealer in Rohini</h2>
          <p>Ganpati Real Estate, a revered name in Rohini's property market, boasts two decades of expertise as a Rera-certified agent. Specializing in buying, selling, and renting homes and PG accommodations, we dominate sectors 6, 7, 8, and beyond. Recognized among the top 10 property dealers in sectors 3 to 8, we excel in handling plots, societies, kothi, and flats across Rohini. Backed by Rera certification, trust Ganpati Real Estate for transparent and professional services. For all your property needs in Rohini, call us at 7042166816.</p>

          <h2 style={{textAlign:"center"}}>Connect For FREE Consultation!</h2>
        
          <div className="social-media-link" >

            <div onClick={handleMapLink} className='locate-icon'>
                {/* <i style={{color:"red", fontSize: "xx-large" }} className="fa-solid fa-location-dot"></i>  */}
                <img src={mapsLoc} style={{width: "32px"}}/>
              </div>
                <a href="https://www.instagram.com/ganpati_realestate_rohini?igsh=MXV1MnJrOTlwam1qNg==" target="_blank" rel="noopener noreferrer"> <img src={insta} style={{width: "32px"}}/> </a>
                
                {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i style={{color:"#0077b5" ,backgroundColor:"white"}} className="fab fa-linkedin"></i></a> */}
                
                <a href="https://www.facebook.com/profile.php?id=61558049436052&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><img src={facebook} style={{width: "36px"}}/></a>
                
                <a href="https://api.whatsapp.com/send?phone=7042166816" target="_blank" rel="noopener noreferrer"><img src={whastapp} style={{width: "32px"}}/></a>

          </div>
        </div>
    </div>

    <div className='text-content'>
        <h1 className='text-in-h'>
        Why We Are The Top Property Dealer in Rohini?
        </h1>
        <p className='text-in-p'>
        When people think of Ganpati Real Estate, they think of ratings in the real estate business. We are far and away the best dealer in Rohini because we have over twenty years of experience that no one else has. We have a lot of experience and are known as the best real estate agents in sectors 6, 7, 8, 9, and 20 to 25. We offer free consultation to help you with all of your real estate needs, whether you want to rent, buy, or sell. We want to ease our clients' minds about any worries they may have. We are proud to say that we are the only property dealer in Delhi that is both ISO and RERA approved. This means that we always meet the highest standards of professionalism and trustworthiness.

        </p>

        <h3 className='text-in-h3'>
          Why Ganpati Real Estate For Property Dealing?
        </h3>
        <p className='text-in-h3'>
        There is a lot of information about real estate sellers on the web and in apps like Google, Yellow Pages, and social media. For this reason, it's not easy to find the best real estate agents in Rohini, especially in Sectors 6, 7, 8, and 20–25. As the best real estate agents in Rohini, we can help you get the most money for your home.

        </p>

        <div className='text-content2'>
          <p> <b>Service Areas in Rohini:</b> Sector 3, Sector 6, Sector 7, Sector 8, Sector 9, Sector 20, Sector 21, Sector 22, Sector 23, Sector 24, and Sector 25.</p>
          <p style={{marginBottom:"0px"}}> <b>Ganpati Real Estate & Interior Designers: </b> We also provide the latest trends, designers, and brands that are shaping the global interiors scene, and giving us a taste of things to come for the modern and contemporary home. The team of designers is very professionals with expertise in real infrastructure, estate, and construction. You can see our work in the Rohini sector 3 - 9 and many other areas of Rohini.</p>
        </div>
    </div>
    

    <div>
          <div className="container2">
            <div>
              <img src={Icon1} alt="Icon" className='icon-size' />
              <p>Budget Flats for Sale</p>
            </div>
            <div>
              <img src={Icon2} alt="Icon" className='icon-size' />
              <p>Residential Properties</p>
            </div>
            <div>
              <img src={Icon3} alt="Icon" className='icon3-size' />
              <p>Properties for Sale</p>
            </div>
            <div>
              <img src={Icon4} alt="Icon" className='icon-size' />
              <p>Properties for Rent </p>
            </div>
          </div>
          {/* <p className="text2">Your overall text goes here</p> */}
    </div>

    <div ref={scrollToRef}>
      {showContacts && <Contacts />}
    </div>

  </div>
  
);
};

export default Services;
