import React from 'react';
import Contact from '../components/Contact';
import HomePage from '../components/HomePage';
import Services from '../components/Services';
import ImageGallery from '../components/ImageGallery';
import '../css/WebPage.css'; // Import the CSS file for styling

import '@fortawesome/fontawesome-free/css/all.min.css';

const handleMapLink = () => {
  const latitude = 28.707846376843317;
  const longitude = 77.12023881204526;
  const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
  window.open(mapUrl, '_blank');
};

function WebPage() {
  return (
    <div>
      <HomePage />
      <section id="About"><Services/></section>
      <section id="Contact"><Contact/></section>
      <section id="Gallery"><ImageGallery/></section>

  </div>

  );
}

export default WebPage;
